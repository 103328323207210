import React, { useState } from "react";
/* import { navigate } from "gatsby"; */
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import axios from "axios";
import Swal from "sweetalert2";
/* import Navbar from "react-bootstrap/Navbar"; */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles.css";

SwiperCore.use([Autoplay]);
SwiperCore.use([Navigation]);
SwiperCore.use([Pagination]);

const IndexPage = () => {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 4000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  /* const [navbar, setNavbar] = useState(false); */
  const [isLoading, setLoading] = useState(false);
  const [nombre, setNombre] = useState("");
  const [email, setEmail] = useState("");
  const [telefono, setTelefono] = useState("");
  const [tipo, setTipo] = useState("");
  const [mensaje, setMensaje] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const dataForm = new FormData();
    dataForm.append("nombre", nombre);
    dataForm.append("email", email);
    dataForm.append("telefono", telefono);
    dataForm.append("tipo", tipo);
    dataForm.append("mensaje", mensaje);
    const respuesta = await axios.post(
      "https://kernel.alcuboarquitectura.com/api/contactofb",
      dataForm
    );
    if (respuesta.status === 200) {
      if (respuesta.data.status === "Ok") {
        setLoading(false);
        e.target.reset();
        Toast.fire({
          icon: "success",
          title: "Mensaje enviado exitosamente",
        });
        window.top.location.href =
          "https://www.alcuboarquitectura.com/gracias-fb";
        /* window.location.replace(
          "https://www.alcuboarquitectura.com/gracias-ga"
        ); */
        /* navigate("/gracias", {
          state: {
            name: nombre,
          },
        }); */
      } else {
        Toast.fire({
          icon: "error",
          title: "Error al enviar la información",
        });
      }
    } else {
      Toast.fire({
        icon: "error",
        title: "Error al enviar la información",
      });
    }
  };

  return (
    <main>
      <Helmet>
        <title>Al Cubo Arquitectura</title>
        <meta
          name="keywords"
          content="al cubo, arquitectos, ecuador, cuenca, portafolio"
        />
        <meta
          name="description"
          content="Estudio de Arquitectura y Construcción sostenible con 18 años de experiencia liderado por los arquitectos Apolo Gavilánez y Valeria Bustos"
        />
        <meta property="og:title" content="Al Cubo Arquitectura" />
        <meta property="og:url" content="https://www.alcuboarquitectura.com/" />
        <meta property="og:type" content="article" />
        <meta
          property="og:description"
          content="Estudio de Arquitectura y Construcción sostenible con 18 años de experiencia liderado por los arquitectos Apolo Gavilánez y Valeria Bustos"
        />
        <meta
          property="og:image"
          content="https://alcuboarquitectura.com/alcubo-banner.jpg"
        />
        <meta property="og:image:type" content="image/jpg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="900" />
      </Helmet>
      <Container fluid>
        {/*         <Row>
          <Col md={12}>
            <Navbar
              variant="light"
              expand="lg"
              fixed="top"
              className={
                navbar ? "menu active-bar shadow mx-auto" : "menu mx-auto"
              }
            >
              <Navbar.Brand className="ms-3">
                <StaticImage
                  src="../images/alcubo-logo.png"
                  alt="Al Cubo logo"
                  className="alcubo-logo"
                />
              </Navbar.Brand>
            </Navbar>
          </Col>
        </Row> */}
        <Row>
          <Col md={12} className="px-0">
            <Swiper
              navigation={true}
              autoplay={true}
              loop={true}
              pagination={{ clickable: true }}
              modules={[Navigation]}
              // slidesPerView={4}
              className="mySwiper"
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 0,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 0,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 0,
                },
              }}
            >
              <SwiperSlide>
                <StaticImage
                  src="../images/carousel/alcubo.jpg"
                  alt="Al Cubo banner"
                  layout="fullWidth"
                />
              </SwiperSlide>
              <SwiperSlide>
                <StaticImage
                  src="../images/carousel/bambu-sierra.jpg"
                  alt="Bambú Sierra banner"
                  layout="fullWidth"
                />
              </SwiperSlide>
              <SwiperSlide>
                <StaticImage
                  src="../images/carousel/casa-cherrez-arias-1.jpg"
                  alt="Casa Cherrez Arias banner"
                  layout="fullWidth"
                />
              </SwiperSlide>
              <SwiperSlide>
                <StaticImage
                  src="../images/carousel/casa-chimborazo.jpg"
                  alt="Casa Chimborazo banner"
                  layout="fullWidth"
                />
              </SwiperSlide>
              <SwiperSlide>
                <StaticImage
                  src="../images/carousel/casa-mirador-el-tablon.jpg"
                  alt="Casa Mirador El Tablón banner"
                  layout="fullWidth"
                />
              </SwiperSlide>
              <SwiperSlide>
                <StaticImage
                  src="../images/carousel/casa-terraza-blanca.jpg"
                  alt="Casa Terraza Blanca banner"
                  layout="fullWidth"
                />
              </SwiperSlide>
            </Swiper>
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Row className="pt-3 pb-2 pb-md-4 bg-black">
          <Col md={12} className="d-flex justify-content-center">
            <div>
              <h1 className="form-title">HABLEMOS</h1>
              <h2>
                <span className="form-subtitle-border">acerca de tu</span>{" "}
                <span className="form-subtitle">próximo proyecto</span>
                <span className="form-subtitle-border">.</span>
              </h2>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="background p-4 p-md-5">
        <Row className="d-flex justify-content-center">
          <Col md={7} className="text-center">
            <h3 className="project-title">
              ¿Estás pensando construir y necesitas un proyecto arquitectónico?
            </h3>
            <p className="project-text mb-2">
              Entonces estás en el lugar correcto. Déjanos tus datos y uno de
              nuestros profesionales te contactará a la brevedad posible.
            </p>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center my-3">
          <Col md={9}>
            <Form className="contact-form" onSubmit={handleSubmit}>
              <Row>
                <Col md={12}>
                  <FloatingLabel label="Nombre" className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Nombre"
                      required
                      onChange={(e) => setNombre(e.target.value)}
                    />
                  </FloatingLabel>
                </Col>
                <Col md={12}>
                  <FloatingLabel label="Email" className="mb-3">
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      required
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </FloatingLabel>
                </Col>
                <Col md={12}>
                  <FloatingLabel label="Teléfono" className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Teléfono"
                      required
                      onChange={(e) => setTelefono(e.target.value)}
                    />
                  </FloatingLabel>
                </Col>
                <Col md={12}>
                  <FloatingLabel label="Tipo de proyecto" className="mb-3">
                    <Form.Select
                      required
                      onChange={(e) => setTipo(e.target.value)}
                    >
                      <option value="">- Seleccione -</option>
                      <option value="Residencial">Residencial</option>
                      <option value="Comercial">Comercial</option>
                      <option value="Desarrollo Inmobiliario">
                        Desarrollo Inmobiliario
                      </option>
                    </Form.Select>
                  </FloatingLabel>
                </Col>
                <Col md={12}>
                  <FloatingLabel label="Mensaje">
                    <Form.Control
                      required
                      as="textarea"
                      placeholder="Mensaje"
                      onChange={(e) => setMensaje(e.target.value)}
                      style={{ height: "100px" }}
                    />
                  </FloatingLabel>
                </Col>
              </Row>

              <Row>
                <Col md={12} className="text-center">
                  {!isLoading && (
                    <Button type="submit" className="btn-dark btn-enviar mt-3">
                      ENVIAR
                    </Button>
                  )}
                  {isLoading && (
                    <Button
                      type="submit"
                      className="btn-dark btn-enviar mt-3"
                      disabled
                    >
                      <FontAwesomeIcon icon={faSpinner} className="fa-spin" />{" "}
                      ENVIANDO...
                    </Button>
                  )}
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>

      {/* <Container fluid>
        <Row className="pt-3 pb-4 bg-black d-flex justify-content-center">
          <Col xs={6} md={3} className="my-auto">
            <div>
              <h3 className="form-title-portfolio">NUESTROS TRABAJOS</h3>
              <h4>
                <span className="form-subtitle-border-portfolio">
                  portafolio
                </span>{" "}
              </h4>
            </div>
          </Col>
          <Col xs={6} md={3} className="my-auto">
            <a href="https://alcuboarquitectura.com/portafolio">
              <Button
                variant="outline-light"
                className="btn-dark btn-portafolio mt-0 mt-md-3"
              >
                VER PORTAFOLIO
              </Button>
            </a>
          </Col>
        </Row>
      </Container> */}
    </main>
  );
};

export default IndexPage;
